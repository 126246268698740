window.addEventListener('load', function() {
    setTimeout(function() {
        var videoPlaceholder = document.querySelector('.hero__video-placeholder');
        var videoWrapper = document.querySelector('.hero__video-wrapper');
        if(videoWrapper){
            var videoUrl = videoWrapper.getAttribute('data-video-url');
        }

        if (videoPlaceholder && videoWrapper && videoUrl) {

            var video = document.createElement('video');
            video.className = 'hero__video';
            video.autoplay = true;
            video.loop = true;
            video.muted = true; 
            video.controls = false;
            video.setAttribute('playsinline', '');

            var source = document.createElement('source');
            source.src = videoUrl;
            source.type = 'video/mp4';

            video.appendChild(source);
            videoWrapper.appendChild(video);

            videoWrapper.style.display = 'block';

            video.addEventListener('canplay', function() {
                videoPlaceholder.style.transition = 'opacity 1s ease';
                videoPlaceholder.style.opacity = '0';
                setTimeout(function() {
                    videoPlaceholder.style.zIndex = '-1';
                }, 1000);
            });
        }
    }, 1000);
});